import { LINKS } from '@utils/constants';
import { SVGPicker, bytesToSize, getExtension } from '@utils/helpers';
import { Link } from 'gatsby';
import React from 'react';

import ButtonGroup from '@components/Layout/ButtonGroup/ButtonGroup';
import PageSection from '@components/Layout/PageSection/PageSection';
import List from '@components/List/List';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Accordion from '@components/Molecule/Accordion/Accordion';
import Breadcrumbs from '@components/Molecule/Breadcrumbs/Breadcrumbs';
import Button from '@components/Molecule/Button/Button';
import Group from '@components/Molecule/Group/Group';
import Copy, { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';

const ProductHelpTemplate = ({ pageContext }: { pageContext: any }) => {
	const { crumbs } = pageContext.breadcrumb;

	const { category, group, products } = pageContext;

	const { uid } = category;
	const { title, downloads, manual } = category.data;

	const groupsUnderCat = group.edges.filter(
		({ node }) => node.data.categorisation.document?.uid === uid
	);

	const productsNotInGroup = products.edges.filter(
		({ node }) => node.data.categorisation.document?.uid === uid
	);

	const categoryDocuments = []
		.concat(downloads, manual)
		.filter(
			({
				download_title,
				download_file,
				manual_title,
				download_manual_file,
			}) =>
				download_title !== null ||
				manual_title !== null ||
				download_manual_file?.url !== null ||
				download_file?.url !== null
		);

	return (
		<Page>
			<SEO title={`${title && title} product help | UHV Design`} />

			<PageSection>
				<div>
					<Breadcrumbs crumbs={crumbs} />

					<H level={2} as="h1" spacing="md">
						Product help
					</H>

					<ButtonGroup spacing="lg">
						<Button
							to={LINKS.PRODUCT_HELP}
							theme="ghost"
							variant="back">
							Back to all product help
						</Button>
					</ButtonGroup>
				</div>
			</PageSection>

			<PageSection background="grey">
				{title && (
					<H level={2}>
						{SVGPicker(uid)}
						<div className="column">
							{title}
							<Copy
								font="md"
								weight="bold"
								style={{ color: '#0277bd' }}>
								<Link to={LINKS.CONTACT}>Contact us</Link> for
								help {title && `on ${title}`}
							</Copy>
						</div>
					</H>
				)}

				{categoryDocuments.length > 0 && (
					<Group>
						<Accordion label={title} small constrict>
							{categoryDocuments.map(
								(
									{
										download_title,
										download_file,
										manual_title,
										download_manual_file,
									}: {
										download_file: {
											url: string;
											size: number;
										};
										download_manual_file: {
											url: string;
											size: number;
										};
										download_title: string;
										manual_title: string;
									},
									key: number
								) => {
									if (
										download_file === null ||
										download_title === null ||
										manual_title === null ||
										download_manual_file === null
									)
										return null;

									const fileType = download_file?.url
										? download_file?.url
										: download_manual_file?.url;
									const fileSize = download_file?.size
										? download_file?.size
										: download_manual_file?.size;

									return fileType && fileSize ? (
										<List>
											<li key={key}>
												{download_title
													? download_title
													: manual_title}
												<Button
													to={
														download_file?.url
															? download_file?.url
															: download_manual_file?.url
													}
													variant="download">
													{`${getExtension(
														fileType
													)} (${bytesToSize(
														fileSize
													)})`}
												</Button>
											</li>
										</List>
									) : null;
								}
							)}
						</Accordion>
					</Group>
				)}

				{groupsUnderCat.length > 0 &&
					groupsUnderCat.map(
						({ node }: { node: any }, key: number) => {
							const { uid } = node;
							const { title } = node.data;

							const productsUnderGroup = products.edges.filter(
								({ node }) =>
									node.data.categorisation.document?.uid ===
									uid
							);

							return productsUnderGroup.length > 0 ? (
								<Group key={key}>
									<H level={3}>{title}</H>

									{productsUnderGroup.map(
										(
											{ node }: { node: any },
											key: number
										) => {
											const { title, downloads, manual } =
												node.data;

											const documents = []
												.concat(downloads, manual)
												.filter(
													({
														download_title,
														download_file,
														manual_title,
														download_manual_file,
													}) =>
														download_title !==
															null ||
														manual_title !== null ||
														download_manual_file?.url !==
															null ||
														download_file?.url !==
															null
												);

											return documents.length > 0 ? (
												<Accordion
													label={title}
													key={key}
													small>
													<Copy font="md">
														Product Downloads
													</Copy>

													<List>
														{documents.map(
															(
																{
																	download_title,
																	download_file,
																	manual_title,
																	download_manual_file,
																}: {
																	download_file: {
																		url: string;
																		size: number;
																	};
																	download_manual_file: {
																		url: string;
																		size: number;
																	};
																	download_title: string;
																	manual_title: string;
																},
																key: number
															) => {
																if (
																	download_file ===
																		null ||
																	download_title ===
																		null ||
																	manual_title ===
																		null ||
																	download_manual_file ===
																		null
																)
																	return null;

																const fileType =
																	download_file?.url
																		? download_file?.url
																		: download_manual_file?.url;
																const fileSize =
																	download_file?.size
																		? download_file?.size
																		: download_manual_file?.size;

																return fileType &&
																	fileSize ? (
																	<li
																		key={
																			key
																		}>
																		{download_title
																			? download_title
																			: manual_title}
																		<Button
																			to={
																				download_file?.url
																					? download_file?.url
																					: download_manual_file?.url
																			}
																			variant="download">
																			{`${getExtension(
																				fileType
																			)} (${bytesToSize(
																				fileSize
																			)})`}
																		</Button>
																	</li>
																) : null;
															}
														)}
													</List>
												</Accordion>
											) : null;
										}
									)}
								</Group>
							) : null;
						}
					)}

				{productsNotInGroup.length > 0 && (
					<Group>
						{productsNotInGroup.map(({ node }) => {
							const { title, downloads, manual } = node.data;

							const documents = []
								.concat(downloads, manual)
								.filter(
									({
										download_title,
										download_file,
										manual_title,
										download_manual_file,
									}) =>
										download_title !== null ||
										manual_title !== null ||
										download_manual_file?.url !== null ||
										download_file?.url !== null
								);

							return documents.length > 0 ? (
								<Accordion label={title} small constrict>
									<Copy font="md">Product Downloads</Copy>

									<List>
										{documents.map(
											(
												{
													download_title,
													download_file,
													manual_title,
													download_manual_file,
												}: {
													download_file: {
														url: string;
														size: number;
													};
													download_manual_file: {
														url: string;
														size: number;
													};
													download_title: string;
													manual_title: string;
												},
												key: number
											) => {
												if (
													download_file === null ||
													download_title === null ||
													manual_title === null ||
													download_manual_file ===
														null
												)
													return null;

												const fileType =
													download_file?.url
														? download_file?.url
														: download_manual_file?.url;
												const fileSize =
													download_file?.size
														? download_file?.size
														: download_manual_file?.size;

												return fileType && fileSize ? (
													<li key={key}>
														{download_title
															? download_title
															: manual_title}
														<Button
															to={
																download_file?.url
																	? download_file?.url
																	: download_manual_file?.url
															}
															variant="download">
															{`${getExtension(
																fileType
															)} (${bytesToSize(
																fileSize
															)})`}
														</Button>
													</li>
												) : null;
											}
										)}
									</List>
								</Accordion>
							) : null;
						})}
					</Group>
				)}
			</PageSection>
		</Page>
	);
};

export default ProductHelpTemplate;
