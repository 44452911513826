import cx from 'classnames';
import React from 'react';

import * as styles from './Group.module.css';

const Group = ({
	children,
	as: Component = 'div',
	className = '',
}: {
	children: React.ReactNode;
	as?: React.ElementType;
	className?: string;
}) => {
	return (
		<Component
			className={cx(styles.group, {
				[className]: !!className,
			})}>
			{children}
		</Component>
	);
};

export default Group;
