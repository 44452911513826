import cx from 'classnames';
import React from 'react';

import * as styles from './List.module.css';

const List = ({
	children,
	as: Component = 'ul',
	className = '',
}: {
	children: React.ReactNode;
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	return (
		<Component className={cx(styles.list, { [className]: !!className })}>
			{children}
		</Component>
	);
};

export default List;
